/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader />

        <Column className="--style2 --left --full" name={"introduction"} style={{"backgroundColor":"var(--color-blend--95)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--1 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1310}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":""}} content={"Ceník<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"3vh67m1kdvt"}>
          
          <ColumnWrap className="column__flex --left el--3 pb--30 pt--30" style={{"maxWidth":1310}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Individual therapy"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Couple and family therapy"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Crisis counseling and intervention"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Hypnosis and hypnotherapy"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box" style={{"maxWidth":300}} content={"General counseling"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Kinderpsychotherapie"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--bottom --parallax pb--80 pt--80" name={"contact"} style={{"backgroundColor":"var(--color-blend--95)"}} parallax={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"} style={{"maxWidth":1310}}>
              
              <Title className="title-box" content={"Lead a life of balance and mental health"}>
              </Title>

              <Button className="btn-box btn-box--shape4" content={"Book a session"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Psychotherapy & counseling"}>
              </Subtitle>

              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>Let's meet Alena Nováková</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"Click here and start writing. This is an area for your text."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":375}} content={"Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>510-851-4014</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"722 Finch Street, Asbury Park, NJ 07712<br>info@vase-stranky.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}